<template>
  <Layout>
    <PageHeader
      :title="this.$route.name == 'add-survey-question' ? title1 : title2"
    ></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="page">
              <div class="row">
                <b-form-group id="input-group-1" class="col-12">
                  <label for="input-1">
                    Question <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="form.question"
                    placeholder="Enter Question"
                    :class="{
                      'is-invalid': submitted && $v.form.question.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.question.required"
                    class="invalid-feedback"
                  >
                    Question is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-12">
                  <label for="form.content_type"
                    >Question Type <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="content_type"
                    :options="questionTypeOptions"
                    v-model="form.question_type"
                    value-field="value"
                    text-field="text"
                    :class="{
                      'is-invalid': submitted && $v.form.question_type.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.question_type.required"
                    class="invalid-feedback"
                  >
                    Question Type is required.
                  </div>
                </b-form-group>
              </div>
              <template
                v-if="form.question_type != 'input' && form.question_type != ''"
              >
                <div
                  class="row"
                  v-for="(val, index) in form.options"
                  :key="index"
                >
                  <div class="col-md-9">
                    <b-form-group id="input-group-1">
                      <label for="input-1"
                        >Option {{ index + 1 }}
                        <span style="color: red">*</span></label
                      >
                      <b-form-input
                        id="input-1"
                        v-model="val.value"
                        :key="val.key"
                        placeholder="Enter Option"
                        @keydown.space="preventLeadingSpace"
                        :class="{
                          'is-invalid': submitted && $v.form.options.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="
                          submitted && $v.form.$model.options[index].value != ''
                        "
                        class="invalid-feedback"
                      >
                        Option is required.
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-3" style="margin-top: 30px">
                    <b-button
                      variant="success"
                      class="mr-3"
                      v-if="form.options.length - 1 == index"
                      @click.prevent="addOption(index)"
                      :disabled="val.value.length == 0"
                      >Add Option</b-button
                    >
                    <b-button
                      variant="danger"
                      @click.prevent="removeOption(index)"
                      >Remove Option</b-button
                    >
                  </div>
                </div>
              </template>
              <template
                v-if="form.question_type != 'input' && form.question_type != ''"
              >
                <b-form-group>
                  <label for="corr"
                    >Select Correct Option
                    <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="corr"
                    v-model="form.correct_answer"
                    :options="form.options.filter((f) => f.value !== '')"
                    value-field="value"
                    text-field="value"
                  >
                  </b-form-select>
                </b-form-group>
              </template>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                ><span v-if="this.$route.name == 'add-survey-question'"
                  >Save Data</span
                >
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/survey-question-list";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      submitted: false,
      title1: "Add Survey Question",
      title2: "Edit Survey Question",
    };
  },
  mixins: [MixinRequest, pageMixin],
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      question: { required },
      question_type: { required },
      options: { required },
    },
  },
  created() {
    this.form.survey_id = this.$route.query.survey_id;
    if (this.$route.name == "edit-survey-question")
      this.fetchSingleQuestion(this.$route.params.id);
  },
};
</script>
