import { survey } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "question",
          class: "w-400",
        },
        {
          key: "question_type",
          label: "Question Type",
          class: "w-200",
        },
        {
          key: "options",
          class: "w-400",
        },
        {
          key: "correct_answer",
          label: "Correct Option",
          class: "w-200",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      params: "",
      form: {
        question: "",
        question_type: "",
        options: [
          {
            key: 0,
            value: "",
          },
        ],
        correct_answer: "",
        survey_id: "",
        question_id: "",
      },
      filter: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
      questionTypeOptions: [
        {
          value: "bullet",
          text: "Bullet",
        },
        {
          value: "checkbox",
          text: "Checkbox",
        },
        {
          value: "option",
          text: "Option",
        },
        {
          value: "input",
          text: "Input",
        },
      ],
    };
  },
  methods: {
    addOption() {
      let length = this.form.options.length;
      this.form.options.push({
        key: length,
        value: "",
      });
    },
    removeOption(key) {
      if (key == 0) return;
      this.form.options.splice(key, 1);
    },
    async handleQuestionSubmit() {
      if (this.question_csv_file == "") {
        this.$store.commit({
          status: true,
          icon: "error",
          title: "Please add question file",
        });
      } else {
        this.$store.commit("loader/updateStatus", true);
        try {
          let formData = new FormData();
          if (this.question_csv_file) {
            formData.append("questions_csv_file", this.question_csv_file);
          }
          let url = `${survey.addQuestionCSV}/${this.surveyId}`;
          const res = await this.postRequest(url, formData);
          console.log(res, "res");
          if (res.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: "File uploaded successfully",
            });
          }
        } catch (err) {
          this.$store.commit({
            status: true,
            icon: "error",
            title: "Something went wrong",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
        }
      } else if (this.filter.length == 0) this.fetchData("search");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${survey.getSurveyQuestionList}/${this.$route.params.id}`;
        if (pagination == "search") {
          url = `${survey.getSurveyQuestionList}/${this.$route.params.id}?search=${this.filter}`;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleQuestion(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = survey.showSingleQuestion + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.question) {
            this.form.question = responseData.question;
          }
          if (
            responseData.question_type != null &&
            responseData.question_type != ""
          ) {
            this.form.question_type = responseData.question_type;
          }
          if (responseData.options != "" && responseData.options != null) {
            this.form.options = responseData.options;
          }
          if (
            responseData.correct_answer != "" &&
            responseData.correct_answer != null
          ) {
            this.form.correct_answer = responseData.correct_answer;
          }
          if (responseData.id) {
            this.form.question_id = responseData.id;
          }
          if (responseData.survey_id) {
            this.form.survey_id = responseData.survey_id;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteSurveyQuestion(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.response.findIndex((e) => e.id === id);
        const url = survey.showSingleQuestion + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.response.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.response.findIndex((e) => e.id === id);
        const url = survey.questionStatusUpdate + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.response[index].is_active,
        });
        if (data.status) {
          this.tableData.response[index] = data.response;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        if (
          this.form.question_type !== "input" &&
          this.form.question_type !== ""
        ) {
          let valid = true;
          this.form.options.forEach((option) => {
            if (!option.value) {
              valid = false;
              return;
            }
          });
          if (!this.form.correct_answer) {
            valid = false;
          }
          if (!valid) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please add options and select correct option",
            });
            return;
          }
        }
        this.$store.commit("loader/updateStatus", true);
        let url = survey.showSingleQuestion;
        if (this.$route.name == "edit-survey-question") {
          url = survey.showSingleQuestion + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        if (this.form.options) {
          dataAppend.append("options", JSON.stringify(this.form.options));
        }
        for (var key in this.form) {
          if (key != "options") {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.$route.name == "edit-survey-question") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/survey-question-list/${this.form.survey_id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `page=${value}`;
        this.fetchData(value);
        this.pageNo = this.currentPage;
      },
    },
  },
};
