var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":this.$route.name == 'add-survey-question' ? _vm.title1 : _vm.title2}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"page",attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v(" Question "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.question.$error,
                  },attrs:{"id":"input-1","placeholder":"Enter Question"},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}}),(_vm.submitted && !_vm.$v.form.question.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Question is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12"},[_c('label',{attrs:{"for":"form.content_type"}},[_vm._v("Question Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.question_type.$error,
                  },attrs:{"id":"content_type","options":_vm.questionTypeOptions,"value-field":"value","text-field":"text"},model:{value:(_vm.form.question_type),callback:function ($$v) {_vm.$set(_vm.form, "question_type", $$v)},expression:"form.question_type"}}),(_vm.submitted && !_vm.$v.form.question_type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Question Type is required. ")]):_vm._e()],1)],1),(_vm.form.question_type != 'input' && _vm.form.question_type != '')?_vm._l((_vm.form.options),function(val,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('b-form-group',{attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Option "+_vm._s(index + 1)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{key:val.key,class:{
                        'is-invalid': _vm.submitted && _vm.$v.form.options.$error,
                      },attrs:{"id":"input-1","placeholder":"Enter Option"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)}},model:{value:(val.value),callback:function ($$v) {_vm.$set(val, "value", $$v)},expression:"val.value"}}),(
                        _vm.submitted && _vm.$v.form.$model.options[index].value != ''
                      )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Option is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-3",staticStyle:{"margin-top":"30px"}},[(_vm.form.options.length - 1 == index)?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success","disabled":val.value.length == 0},on:{"click":function($event){$event.preventDefault();return _vm.addOption(index)}}},[_vm._v("Add Option")]):_vm._e(),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.removeOption(index)}}},[_vm._v("Remove Option")])],1)])}):_vm._e(),(_vm.form.question_type != 'input' && _vm.form.question_type != '')?[_c('b-form-group',[_c('label',{attrs:{"for":"corr"}},[_vm._v("Select Correct Option "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{attrs:{"id":"corr","options":_vm.form.options.filter((f) => f.value !== ''),"value-field":"value","text-field":"value"},model:{value:(_vm.form.correct_answer),callback:function ($$v) {_vm.$set(_vm.form, "correct_answer", $$v)},expression:"form.correct_answer"}})],1)]:_vm._e(),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-survey-question')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }